import { useEffect, useRef } from "react";

/**
 *  Invokes a callback on a specified interval.
 *
 * @param callback callback to invoke on an interval
 * @param delay duration in ms to wait between callback invocation
 * @return a cancellation function
 */
export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef(callback);

  const intervalId = useRef<number | undefined>(undefined);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => {
      savedCallback.current();
    };

    const id = window.setInterval(handler, delay);
    intervalId.current = id;

    return () => clearInterval(id);
  }, [delay]);

  return () => clearInterval(intervalId.current);
}
