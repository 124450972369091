import React, { useEffect, useState } from "react";
import { useInterval } from "./utils";
import "./App.css";
import Axios from "axios";
import { Line } from "react-chartjs-2";

const GET_REPORT_URL =
  "https://air-garden-air-quality-backend.onrender.com/get_report";

interface DataPoint {
  co2_ppm: number;
  rh_percent: number;
  temperature_c: number;
  timestamp: number;
}

const App: React.FC = () => {
  const [values, setValues] = useState<DataPoint[]>([]);

  const getReport = async () => {
    try {
      const response = await Axios.get(GET_REPORT_URL);
      const data = response.data.data_points;
      data.reverse();
      setValues(data);
      if (values.length > 0) {
        document.title = `${values[0]["co2_ppm"].toFixed()} ppm`;
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  useInterval(getReport, 5 * 1000);

  return (
    <div className="app">
      <header className="app-header">CO2 reports at Air Garden</header>
      <div className="app-body">
        <Line
          data={{
            datasets: [
              {
                label: "CO2 (ppm)",
                yAxisID: "CO2",
                pointBorderColor: "red",
                pointHoverBackgroundColor: "red",
                showLine: true,
                borderColor: "red",
                pointRadius: 1,
                data: values.map((value) => {
                  return {
                    x: new Date(value.timestamp * 1000),
                    y: value.co2_ppm,
                  };
                }),
              },
              {
                label: "Relative Humidity (%)",
                yAxisID: "RH",
                pointBorderColor: "green",
                pointHoverBackgroundColor: "green",
                showLine: true,
                borderColor: "green",
                pointRadius: 1,
                data: values.map((value) => {
                  return {
                    x: new Date(value.timestamp * 1000),
                    y: value.rh_percent,
                  };
                }),
              },
              {
                label: "Temperature (C)",
                yAxisID: "Temp",
                pointBorderColor: "blue",
                pointHoverBackgroundColor: "blue",
                showLine: true,
                borderColor: "blue",
                pointRadius: 1,
                data: values.map((value) => {
                  return {
                    x: new Date(value.timestamp * 1000),
                    y: value.temperature_c,
                  };
                }),
              },
            ],
          }}
          options={{
            title: {
              text: "Air Garden Air Quality",
              display: true,
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  type: "time",
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Time",
                  },
                },
              ],

              yAxes: [
                {
                  id: "CO2",
                  type: "linear",
                  position: "left",
                  ticks: {
                    stepSize: 50,
                    fontColor: "red",
                  },
                },
                {
                  id: "RH",
                  type: "linear",
                  position: "right",
                  ticks: {
                    stepSize: 5,
                    fontColor: "green",
                  },
                },
                {
                  id: "Temp",
                  type: "linear",
                  position: "right",
                  ticks: {
                    fontColor: "blue",
                    stepSize: 1,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  );
};

export default App;
